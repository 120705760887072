import React from "react";
import styled from "styled-components";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import Button from "../components/Button";
import Owl from "../components/comp-layouts/Owl";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not Found" />
      <NotFound>
        <Owl margin="1.5rem">
          <h1>404 Not Found</h1>
          <p>This content isn't available, or doesn't exist.</p>
          <Button align="auto" link="true" to="/">
            Return Home
          </Button>
        </Owl>
      </NotFound>
    </Layout>
  );
};

const NotFound = styled.section`
  height: 90vh;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default NotFoundPage;
